.urlfetch {
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

.urlfetch>*:not(:last-child) {
  margin-right: 5px;
}

.tables {
  margin-top: 30px;
  display: flex;
  justify-content: center;

}

.tables table {
  padding: 15px;
  border: 1px solid black;
}

.tables th {
  text-align: left;
}

.tables td {
  text-align: center;
}

.tables tr {
  border-bottom: 1px solid black;
}

.green_span {
  color: green;
  font-weight: bold;
}

.red_span {
  color: red;
  text-decoration: line-through;
  font-weight: bold;
}

.video-picker-container {
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

.video-picker-div {
  text-align: center;
}